import { useState, useEffect } from "react";
import Header from "components/Header";
import PhoneHeader from "components/PhoneHeader";
import fuguRight from "assets/img/banner/fuguRight.png";
import bannerTitle from "assets/img/banner/bannerTitle.png";
import EFF from "assets/img/banner/EFF.png";
import "./index.scss";

const HomeContent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  // const handleNite = () => {
  //   window.open("https://meme.fugu.club/", "_blank");
  // };

  const handleMouseEnter = () => {
    setTooltip(true);
  };

  const handleMouseLeave = () => {
    setTooltip(false);
  };

  const handleNite = () => {
    setTooltip(true);
    setTimeout(() => setTooltip(false), 2000);
  };

  const handleOnClick = () => {
    window.open("https://t.me/FuguToxicBot", "_blank");
  };
  return (
    <div className="home_box">
      <CurrentHeader />
      <div className="home_content_box">
        <div className="home_content">
          <div className="content_left">
            <div className="home_title">
              <div> Fugu toxic</div>
              <img src={bannerTitle} alt=""></img>
              <img src={EFF} alt=""></img>
            </div>
            <div className="text_box">
              <div className="home_des">
                A portion of the transaction fee will be automatically donated
                to Electronic Frontier Foundation
              </div>
            </div>

            <div className="sec_des ">
              The Electronic Frontier Foundation (EFF), founded in 1990 and
              headquartered in San Francisco, USA, is a non-profit international
              legal organization dedicated to defending civil liberties in the
              digital age.
            </div>
            {!isMobile && (
              <div className="home_btn">
                <div
                  className="nite_btn disabled"
                  onClick={handleNite}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  FUGU SWAP
                  {tooltip && <div className="tooltip">Coming Soon</div>}
                </div>
                <div className="mini_btn" onClick={handleOnClick}>
                  FUGU APP
                </div>
              </div>
            )}
          </div>
          <div className="content_right">
            <img src={fuguRight} alt=""></img>
          </div>
        </div>
        {isMobile && (
          <div className="home_btn">
            <div
              className="nite_btn disabled"
              onClick={handleNite}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              FUGU SWAP
              {tooltip && <div className="tooltip">Coming Soon</div>}
            </div>
            <div className="mini_btn" onClick={handleOnClick}>
              FUGU APP
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HomeContent;
