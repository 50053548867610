import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./index.scss";

const items = [
  {
    title: "ZEN",
    description: "Harmony in Crypto",
    img: "/img/zen.gif",
    background: "rgba(255, 75, 75, 0.60)",
  },
  {
    title: "LOTUS",
    description: "Growth Through Balance",
    img: "/img/lotus.gif",
    background: "rgba(20, 239, 255, 0.30)",
  },
  {
    title: "LGBT",
    description: "Pride in Diversity",
    img: "/img/lgbt.gif",
    background: "rgba(240, 255, 24, 0.65)",
  },
  {
    title: "LIBER",
    description: "Freedom for All",
    img: "/img/liber.gif",
    background: "rgba(255, 153, 0, 0.30)",
  },
  {
    title: "LOA",
    description: "Power of Attraction",
    img: "/img/loa.gif",
    background: "rgba(104, 192, 255, 0.30)",
  },
  {
    title: "ATTR",
    description: "Manifest Your Dream",
    img: "/img/attr.gif",
    background: "rgba(255, 63, 188, 0.30)",
  },
  {
    title: "HPY",
    description: "Stupid Dog in Tokyo",
    img: "/img/hpy.gif",
    background: "rgba(255, 0, 0, 0.30)",
  },
  {
    title: "BORG",
    description: "Resistance is Futile",
    img: "/img/borg.gif",
    background: "rgba(255, 214, 214, 0.30)",
  },
  {
    title: "Walden",
    description: "Run to Freedom",
    img: "/img/walden.gif",
    background: "rgba(255, 161, 9, 0.47)",
  },
];

const swiperItems = [
  {
    title: "ZEN",
    img: "/img/zen.gif",
    background: "#FFE501",
  },
  {
    title: "LOTUS",
    img: "/img/lotus.gif",
    background: "#32A4BE",
  },
  {
    title: "LGBT",
    img: "/img/lgbt-b.gif",
    background: "#0FC",
  },
  {
    title: "LIBER",
    img: "/img/liber.gif",
    background: "#FF8C9E",
  },
  {
    title: "LOA",
    img: "/img/loa-b.gif",
    background: "#FFCD68",
  },
  {
    title: "ATTR",
    img: "/img/attr.gif",
    background: "#DE8775",
  },
  {
    title: "HPY",
    img: "/img/hpy.gif",
    background: "#FF6997",
  },
  {
    title: "BORG",
    img: "/img/borg.gif",
    background: "#A6FA38",
  },
  {
    title: "Walden",
    img: "/img/walden.gif",
    background: "#5FB0FB",
  },
  {
    title: "0612hk",
    img: "/img/loa.gif",
    background: "#FFCD68",
  },
  {
    title: "MACH",
    img: "/img/mach.gif",
    background: "#DE8775",
  },
  {
    title: "OVRL",
    img: "/img/ovrl.gif",
    background: "#FF6997",
  },
];

const Carousel = () => {
  return (
    <Swiper
      modules={[Autoplay, Navigation]}
      spaceBetween={20}
      slidesPerView={9}
      loop={true}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      breakpoints={{
        320: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 9,
        },
        1440: {
          slidesPerView: 9,
        },
      }}
      style={{
        background: "radial-gradient(circle, #3a3a3a, #2e2e2e, #1f1f1f, #0f0f0f)",
        padding: "60px 0",
        // width: "100%",
        width: "107%",
        margin: "0px -23px",
      }}
    >
      {swiperItems.map((item, index) => (
        <SwiperSlide key={index}>
          <div
            className="swiper-slide-content"
            style={{ background: item.background }}
          >
            <img
              src={item.img}
              alt={item.title}
            />
            <h3>{item.title}</h3>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const ScrollCarousel = () => {
  return (
    <>
      <div className="box">
        <h1 className="title">Coming soon</h1>
        {/* <h1 className="title">New Virtual Currency</h1> */}
        <p className="detail">
          Fugu is unique in that it hides all details of the transaction, including the sender's identity and the transaction amount, protecting everyone's privacy.
        </p>
        <div className="grid-container">
          {items.map((item, index) => (
            <div key={index} className="grid-item" style={{ background: item.background }}>
              <img src={item.img} alt="" className="avatar" />
              <div className="description-container">
                <p>{item.title}</p>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Carousel />
      </div>
    </>
  )
};

export default ScrollCarousel;
