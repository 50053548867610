import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const BubbleAnimation = () => {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    // 定时生成泡泡
    const interval = setInterval(() => {
      setBubbles((prev) => {
        return [
          ...prev,
          {
            id: new Date().getTime(),
            size: Math.random() * 150 + 30,
            left: Math.random() * 100,
            duration: Math.random() * 5 + 3,
            bottom: -20
          },
        ];
      });
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 0,
      }}
    >
      {bubbles.map((bubble) => (
        <Box
          key={bubble.id}
          component="img"
          src="/assets/img/bubble.png" // 使用泡泡图片
          sx={{
            position: "absolute",
            // bottom: 0,
            left: `${bubble.left}%`,
            width: `${bubble.size}px`,
            height: `${bubble.size}px`,
            bottom: '-300px',
            animation: `bubbleUp ${bubble.duration}s ease-in`,
            pointerEvents: "none", // 确保不可点击
          }}
          onAnimationEnd={() =>
            // 移除已经到达顶部的泡泡
            setBubbles((prev) => prev.filter((b) => b.id !== bubble.id))
          }
        />
      ))}

      {/* 添加 CSS 动画 */}
      <style>
        {`
          @keyframes bubbleUp {
            0% {
              transform: translateY(20vh);
              opacity: 1;
            }
            100% {
              transform: translateY(-120vh);
              opacity: 0;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default BubbleAnimation;
