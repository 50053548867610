// import React, { useState, useEffect } from "react";
// import { fetchGraphQLData } from "service/graph";
import copy from "assets/img/Burve/copy.png";
import bnb from "assets/img/Chat/bnb-bnb-logo 1.png";

import "./index.scss";
import { useEffect, useState } from "react";
import { fetchGraphQLData } from "service/graph";

export function formatAddress(address) {
  if (!address) {
    return;
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

const Overview = () => {
  // const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  console.log("data", data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        const result = await fetchGraphQLData();
        setData(result);
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="overview-title">Fugu Token Overview</div>
      <div className="overview-des">
        Buy and stake during the presale before prices skyrocket!
      </div>
      <div className="overview-container">
        <div className="overview-content">
          {/* 左侧图表 */}
          <div className="chart-container">
            <iframe
              src="https://dev-chart.pages.dev/?address=0xe9ce0984f9d683210b45159ad3616e210f749091&chainId=11155111"
              style={{
                width: "100%",
                height: "400px",
                border: "none",
                borderRadius: "10px",
              }}
              title="Fugu Chart"
            />
          </div>

          {/* 右侧动态数据 */}
          <div className="data-container">
            <div className="data-item">
              <div className="label">Price of Fugu</div>
              <div className="value">
                {"-"}
                {/* {Number(data?.tokenEntity?.currentPrice)?.toFixed(4) || "-"} */}
              </div>
            </div>
            <div className="data-item">
              <div className="data-usdt">
                <div className="label">Total market cap</div>
                <div className="usdt"> usdt</div>
              </div>

              <div className="value">
                {"-"}
                {/* {Number(data?.tokenEntity?.marketCap)?.toFixed(2) || "-"} */}
              </div>
            </div>
            <div className="data-item">
              <div className="label">On-chain traders</div>
              <div className="value">{"-"}</div>
              {/* <div className="value">{data?.tokenEntity?.txCount || "-"}</div> */}
            </div>
            <div className="data-item">
              <div className="label">Transaction volume</div>
              <div className="value">
                {"-"}
                {/* {data?.mintBurnEntities?.[0]?.tradeVolumes || "-"} */}
              </div>
            </div>
            <div className="data-item">
              <div className="label">Fugu Token Contracts</div>
            </div>
            <div className="data-item">
              <div className="wallet">
                <div className="wallet-icon">
                  <img src={bnb} alt=""></img>
                </div>
                <div className="wallet-address">
                  <p>BNB SmartChain（BSC）</p>
                  <p>0xAAA******AAAA</p>
                  {/* <p>{formatAddress(data?.tokenEntity?.addr)}</p> */}
                </div>
                <div className="wallet-copy">
                  <img src={copy} alt=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
