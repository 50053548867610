import { Box } from "@mui/material";
import BubbleAnimation from "./BubbleAnimation";

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: 'hidden',
        overflowY: 'scroll',
        backgroundImage: {
          xs: "url('/assets/img/mb-bg.png')",
          sm: "url('/assets/img/bg.png')",
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "text.primary",
      }}
    >
      <Box
        component="main"
        sx={{
          flex: 1,
          p: { md: 3, xs: 0 },
          position: 'relative'
        }}
      >
        <BubbleAnimation />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
