import { useState } from "react";
import X from "assets/img/footer/X.png";
import copy from "assets/img/Burve/copy.png";
import fuguFNT from "assets/img/Burve/bsc.png";
import burveGirl from "assets/img/Burve/burve-girl.png";
import burveGirlMb from "assets/img/Burve/burve-girl-mb.png";
import fuguLogo from "assets/img/Burve/fugu-logo.png";
import burveLogo from "assets/img/Burve/burve-logo.png";
import money from "assets/img/Burve/money.png";
import wallet from "assets/img/Burve/wallet.png";
import "./index.scss";
import useAddressBalance from "hooks/useAddressBalance";

const walletAddress = "0xAd8DC0Be59b5B94a994Ba7F33299650B089a13E9";

const Burve = () => {
  const [copied, setCopied] = useState(false);
  const { balance } = useAddressBalance(walletAddress, 3600000);

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <div className="freedom-banner">
        <div className="banner-content">
          <div className="fish-icon">
            <img src={fuguFNT} alt="Fish Icon" />
          </div>
          <div className="info-section">
            <h3 className="banner-title">
              Supported By
              <br />
              Freedom Spirit Fundation
            </h3>
            <p style={{ textAlign: "left" }}>
              People around the world are uniting to reclaim their freedom.
              <br /> Join us in the fight for our future!
            </p>
            <div className="balance-wallet">
              <div className="balance">
                <div className="balance-icon">
                  <img src={money} alt=""></img>
                </div>
                <div className="balance-amount">
                  <span className="amount">₮ {walletAddress === "Coming soon" ? "-" : balance?.toLocaleString('en-US', { maximumFractionDigits: 3 })}</span>
                  <span className="balance-unit">USDT</span>
                </div>
              </div>
              <div className="wallet">
                <div className="wallet-icon">
                  <img src={wallet} alt=""></img>
                </div>
                <div className="wallet-address">
                  <span className="address">
                    {walletAddress === "Coming soon" 
                      ? "Coming soon" 
                      : <>
                          {`${walletAddress.slice(0, 21)}`}<br/>
                          {`${walletAddress.slice(21)}`}
                        </>
                    }
                  </span>
                  <button className="copy-button" onClick={copied ? null : handleCopy}>
                    {!copied ? <img src={copy} alt=""></img> : <span className="copied">Copied</span>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_burve">
        <div className="footer_bg">
          <img src={burveGirl} alt=""></img>
        </div>
        <div className="content">
          <div className="footer_title">powered by burve.io</div>
          <div className="footer_desc">
            Freedom of speech is the cornerstone of fairness and equality
          </div>
          <div className="footer_logo">
            <img src={fuguLogo} alt=""></img>
            <img src={X} alt="" className="x-logo"></img>
            <img src={burveLogo} alt=""></img>
          </div>
          <svg
            className="logo star-1"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 45 45"
            fill="none"
          >
            <path
              d="M22.5 0L28.577 16.423L45 22.5L28.577 28.577L22.5 45L16.423 28.577L0 22.5L16.423 16.423L22.5 0Z"
              fill="#EDB600"
            />
          </svg>
          <svg
            className="logo star-2"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 45 45"
            fill="none"
          >
            <path
              d="M22.5 0L28.577 16.423L45 22.5L28.577 28.577L22.5 45L16.423 28.577L0 22.5L16.423 16.423L22.5 0Z"
              fill="#EDB600"
            />
          </svg>
          <svg
            className="logo star-3"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
          >
            <path
              d="M8.5 0L10.7958 7.29909L17 10L10.7958 12.7009L8.5 20L6.20423 12.7009L0 10L6.20423 7.29909L8.5 0Z"
              fill="#D9D9D9"
            />
          </svg>
          <svg
            className="logo star-4"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 34 41"
            fill="none"
          >
            <path
              d="M17 0L21.5915 14.9631L34 20.5L21.5915 26.0369L17 41L12.4085 26.0369L0 20.5L12.4085 14.9631L17 0Z"
              fill="#EDB600"
            />
          </svg>
          <svg
            className="logo star-5"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="16"
            viewBox="0 0 17 20"
            fill="none"
          >
            <path
              d="M8.5 0L10.7958 7.29909L17 10L10.7958 12.7009L8.5 20L6.20423 12.7009L0 10L6.20423 7.29909L8.5 0Z"
              fill="#D9D9D9"
            />
          </svg>
        </div>
        <div className="mb_footer_bg">
          <img src={burveGirlMb} alt=""></img>
        </div>
      </div>
    </>
  );
};

export default Burve;
