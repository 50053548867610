import { useEffect, useState } from "react";
import { JsonRpcProvider, ethers } from "ethers";

const ETH_RPC_URL = "https://eth-mainnet.g.alchemy.com/v2/CT1kclgvarV9dN_CKSODIQ_AYMwl36Fs";
const USDT_CONTRACT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

const useAddressBalance = (address, interval = 5000) => {
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!address) {
      setError("no address :sad:");
      return;
    }

    const provider = new JsonRpcProvider(ETH_RPC_URL);

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const USDT_ABI = [
          "function balanceOf(address owner) view returns (uint256)",
          "function decimals() view returns (uint8)",
        ];

        const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, provider);

        const rawBalance = await usdtContract.balanceOf(address);
        const decimals = await usdtContract.decimals();

        const formattedBalance = parseFloat(ethers.formatUnits(rawBalance, decimals));

        setBalance(formattedBalance);
      } catch (err) {
        setError(err.message || "fetching data failed");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const timer = setInterval(fetchData, interval);

    return () => clearInterval(timer);
  }, [address, interval]);

  return { balance, loading, error };
};

export default useAddressBalance;
