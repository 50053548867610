export async function fetchGraphQLData() {
  const url = "https://api.studio.thegraph.com/query/93762/fugu/version/latest";

  const query = `
    query MyQuery {
      tokenEntity(id: "0xe9ce0984f9d683210b45159ad3616e210f749091") {
        addr
        admin
        createTimestamp
        creator
        currentPrice
        lockValue
        initPrice
        memberCount
        marketCap
        raisingToken
        mintTax
        decimal
        burnTax
        supply
        txCount
      }
      user(id: "0xe9ce0984f9d683210b45159ad3616e210f749091") {
        members {
          balance
          id
        }
      }
      prices(
        where: {id: "0xe9ce0984f9d683210b45159ad3616e210f749091", type: "1m"}
        first: 10
        skip: 10
        orderBy: timestamp
      ) {
        maxPrice
        id
        closePrice
        baseVolume
        minPrice
        openPrice
        timestamp
        tokenTvl
        type
        volume
      }
      mintBurnEntities(where: {token: "0xe9ce0984f9d683210b45159ad3616e210f749091"}) {
        blockNum
        direction
        erc20Amount
        from
        hash
        id
        nativeAmount
        platformFee
        price
        projectFee
        timestamp
        to
        tradeVolumes
        tvl
      }
    }
  `;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.errors) {
      console.error("GraphQL errors:", result.errors);
      throw new Error("Failed to fetch GraphQL data.");
    }

    return result.data;
  } catch (error) {
    console.error("Error fetching GraphQL data:", error);
    throw error;
  }
}
