import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "assets/img/header/logo.svg";
import whitePaperPdf from "assets/img/header/White paper.pdf";

const PhoneHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleX = () => {
    window.open("https://twitter.com/fugutoxic01", "_blank");
    setAnchorEl(null);
  };
  const handletel = () => {
    window.open("https://t.me/fugutoxicannouncementchannel", "_blank");
    setAnchorEl(null);
  };
  const textStyle = {
    color: "#B0B2B6",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20.8px",
    cursor: "pointer",
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#0F1927",
        padding: "12px 0px 10px 20px",
      }}
    >
      <div>
        <img src={logo} alt=""></img>
      </div>
      <div>
        <Button
          id="basic-button"
          onClick={handleClick}
          style={{ color: "white" }}
        >
          <MenuIcon />
        </Button>
        <Menu
          disableScrollLock={true}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: "#18263D",
              marginTop: "9px",
              marginLeft: "15px",
            },
          }}
        >
          <MenuItem onClick={handleClose}>
            <a
              href={whitePaperPdf}
              target="_blank"
              rel="noopener noreferrer"
              style={textStyle}
            >
              White paper
            </a>
          </MenuItem>
          <MenuItem onClick={handleX}>
            <span style={textStyle}>X</span>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <span style={textStyle} onClick={handletel}>
              Telegram
            </span>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            <span style={textStyle}>Discord</span>
          </MenuItem> */}
        </Menu>
      </div>
    </div>
  );
};

export default PhoneHeader;
